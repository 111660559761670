<template>
  <div style="background-color: white;min-height: 100vh">
    <el-container style="border: 1px solid #eee">
      <el-container>
        <el-header>
        </el-header>
        <el-main>
          <el-form ref="form" :model="form" label-width="110px" inline>
            <el-form-item label="code">
              <el-input v-model="form.key"></el-input>
            </el-form-item>
            <el-button type="primary" @click="getData">生成</el-button>
          </el-form>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button
                  :data-clipboard-text="code"
                  class="copy"
                  @click="$popup({msg:'复制成功',type:'success'})"
              >复制</el-button>

              <pre>{{ code }}</pre>
            </el-col>
            <el-col :span="12">
              <el-button
                  :data-clipboard-text="code1"
                  class="copy"
                  @click="$popup({msg:'复制成功',type:'success'})"
              >复制</el-button>

              <pre>{{ code1 }}</pre>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import request from "@/utils/request"
import ClipboardJS from "clipboard"

export default {
  name: "Apifox",
  data() {
    return {
      baseURL: '',
      schema: {},
      form: {
        key: '28750929'
      },
      code: '',
      code1: ''
    }
  },
  mounted() {
    new ClipboardJS('.copy')
  },
  methods: {
    getData(){
      request.get(
          '/shared-docs/282cd330-e99a-47bb-babe-0a61beab2cc1/http-apis/' + this.form.key,
          {baseURL: this.baseURL}
      ).then(r => {
        this.schema = r.data
        this.code = this.general()
        this.code1 = this.general1()
      })
    },
    general(){
      const properties = this.schema.requestBody.jsonSchema.properties

      let constructorParam = ''
      let assignment = ''

      Object.keys(properties).forEach(key => {
        constructorParam += `    ${key} = undefined,
`
        assignment += `    /**
     * ${properties[key].title}
     * @type ${properties[key].type}
     */
    this.${key} = ${key}
`
      })

      return `export default class {
  constructor(
${constructorParam}  ) {
${assignment}  }
}
`
    },
    general1(){
      const properties = this.schema.requestBody.jsonSchema.properties

      let assignment = ''

      Object.keys(properties).forEach(key => {
        assignment += `    /**
     * ${properties[key].title}
     * @type ${properties[key].type}
     */
    ${key}: ${properties[key].type === 'array' ? '[]' : 'undefined'},
`
      })

      return `export default {
${assignment}
}
`
    }
  }
}
</script>

<style scoped>

</style>
